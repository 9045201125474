export const priceData = [
    {
        name: "Beginner",
        addText: "з куратором",
        price: 840,
        prevPrice: 950,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b149b963dd51c",
        avlServices: [
            "уроки категорії Beginner",
            "документація",
            "супровід куратора та зворотній звʼязок",
            "практичні завдання з перевіркою",
            "телеграм-група MopisSchool",
            "диплом"
        ],
        notAvlServices: []
    },

    {
        name: "Pro",
        addText: "самостійний",
        price: 950,
        prevPrice: 1100,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/bc7e024229bcb",
        avlServices: [
            "уроки категорії Pro",
            "повний пакет документації (укр та англ)",
            "поглиблені уроки із запрошеними спікерами",
            "телеграм-група MopisSchool",
            "диплом"
        ],
        notAvlServices: []
    },

    {
        name: "Pro",
        addText: "з куратором",
        price: 1200,
        prevPrice: 1400,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b0ac3578622b5",
        avlServices: [
           "всі уроки категорії Pro",
            "повний пакет документації (укр та англ)",
            "поглиблені уроки із запрошеними спікерами",
            "телеграм-група MopisSchool",
            "диплом",
            "2 поглиблених вебінари від Аліси",
            "супровід куратора",
            "практичні завдання з перевіркою (рівень pro)",
            "зворотній зв'язок особисто від Аліси"

        ],
        notAvlServices: []
    },
];


//////////


export const extraPriceData = [
    {
        name: "Beginner + Pro ",
        addText: "з куратором",
        price: 1650,
        prevPrice: 1850,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b1ff728dc7b61",
        avlServices: [],
        notAvlServices: []
    },

    {
        name: "Весілля в Європі",
        addText: "",
        price: 800,
        prevPrice: 1050,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b8f37c68b2cd3",
        avlServices: [],
        notAvlServices: []
    },
];


////////////


export const mentoringData = [
    `Ментор — Аліса, супровід 6 місяців`,
    "Особисте планування кар’єрного шляху з Алісою",
    "практика на масштабному проєкті Mopis",
]