import DOC from '../../images/doc-icon.svg';
import PDF from '../../images/pdf-icon.svg';
import MP4 from '../../images/mp4-icon.svg';
import XLSX from '../../images/xlsx-icon.svg';
import JPG from '../../images/jpg-icon.svg';

// This Icon Is In Design, But Never Used
// import PPTX from '../../images/pptx-icon.svg';


// export const programDataBegginer = [

//     // BLOCK #0

//     {
//         name: "Преднавчання",
//         description: "В результаті ми розберемо усі організаційні питання, задамо правильний настрій і попрацюємо над мисленням для максимально ефективного навчання",
//         stepsBegginer: [
//             "Welcome-зустріч “Знайомство”",
//             "Inspiration відео “Як отримати від навчання максимум”",
//             "Мотиваційне відео від команди Mopis (знято в Італії)",
//             "Зустріч із психологом “Мислення для успіху — долаємо страхи та мотивуємо себе на результативне навчання”",
//             "Налаштовуємося на хвилю dream-проєктів — підбірка натхнення",
//             "Цілі на курс",
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     },

//     // BLOCK #1

//     {
//         name: "Вступний",
//         description: "В результаті ти отримаєш уявлення про роботу весільного організатора на реальних прикладах. Підготуєш повну базу для подальшої роботи",
//         stepsBegginer: [
//             "Знайомство з професією “Весільний організатор”. Обовʼязки організатора",
//             "Різниця між організатором і координатором",
//             "Стандарти та принципи роботи організатора ",
//             "Комунікація із замовником",
//             "Договір Весільного організатора (укр, англ)",
//             "Програми для планування роботи",
//             "Глосарій організатора (ключові терміни які використовуємо в роботі)",
//             "Поділ весіль за масштабами",
//             "Зум з запрошеним спікером"
//         ],
//         stepsProfi: [
//             // "Команда: з чого почати, які посади важливі. Part-time & Full time. Домовленості, оплати",
//             // "Делегування. Як довірити комусь своє весілля. Про організацію роботи, контроль, довіру та відповідальність",
//             // "Робота з дорогим замовником: помилки, принципи, поради",
//             // "Типи складних замовників і комунікація з ними",
//             // "Організація в Україні та Європі: ключові відмінності",
//             // "Як почати організовувати весілля в Європі",
//         ],
//         addMaterials: [
//             {
//                 icon: PDF,
//                 text: "Організація весілля від Mopis"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад резюме Mopis"
//             },
//             {
//                 icon: MP4,
//                 text: "Планування проєкту в Asana"
//             },
//             {
//                 icon: PDF,
//                 text: "Шаблон резюме"
//             },
//             {
//                 icon: PDF,
//                 text: "Координація весілля від Mopis"
//             },
//             {
//                 icon: PDF,
//                 text: "Внутрішні стандарти роботи Mopis"
//             },
//             {
//                 icon: PDF,
//                 text: "Стандарти спілкування з нареченими Mopis"
//             },
//             {
//                 icon: MP4,
//                 text: "Перший досвід організації весілля закордоном"
//             },
//             {
//                 icon: DOC,
//                 text: "Договір - організація весілля Mopis"
//             },
//             {
//                 icon: DOC,
//                 text: "Agreement Mopis"
//             },
//             {
//                 icon: DOC,
//                 text: "Розписка про отримання коштів Mopis"
//             },
//         ]
//     }, 

//     // BLOCK #2

//     {
//         name: "Підготовка весілля. Покроковий розбір",
//         description: "В результаті ти навчишся правильно виконувати всі етапи з підготовки весілля. Отримаєш необхідний список інструментів та шаблонів. Спроєктуєш знання на реальні приклади і кейси",
//         stepsBegginer: [
//             "Чек-лист підготовки весілля",
//             "Перші кроки після підписання договору", 
//             "Кошторис проєкту",
//             "Підбір локації для пари. Підготовка презентації: смислові, візуальні, технічні особливості",
//             "Підбір команди: фото, відео, ведучий, музичний супровід",
//             "Приклад анкети для наречених Mopis",
//             "Розробка концепції весілля. Приклади концепцій Mopis",
//             "Розбір концептуального весілля Mopis",
//             "Запрошення та поліграфія",
//             "Візуальна складова свята. Комунікація з декораторами",
//             "Формування таймінгу весільного дня. Наповнення свята",
//             "Підготовка вінчання та церемонії",
//             "Робота з зірковими артистами",
//             "Складання меню на весілля",
//             "Технічне забезпечення свята",
//             "Зонування локації",
//             "Запис зуму з дизайнеркою презентацій А. Глазовою",
//             "Зум з запрошеним спікером"
//         ],
//         stepsProfi: [
//             // "Організація роботи, коли в роботі паралельно 10+ проєктів. Розподіл часу та пріоритетів",
//             // "Стандарти сервісу та перевершення очікувань (Діана, Івент-менеджер Edem Resort) ", 
//             // "Презентації клієнтам — брендування та нові стандарти (А.Глазова, best of the best дизайнерка презентацій, котра у свій час створила найтоповіші івенти Києва)",
//             // "Розбір концепції весілля з бюджетом 100 000+ $",
//             // "Кошторис проєкту: як отримати портфолійну картинку при дуже обмеженому кошторисі (з прикладами Mopis) ",
//             // "Кошторис проєкту: як працювати з кошторисом, коли бюджет великий ",
//             // "Кошторис проєкту: як збільшити дохід організатора",
//             // "Весілля в Європі: відмінності в чек-листі, кошторисі та процесі планування",
//             // "Весілля в Європі: логістика (команди, декору, техніки). Митні документи",
//             // "Весілля в Європі: робота з локацією (підбір, комунікація, презентація для клієнта) ",
//             // "Весілля в Європі: де шукати підрядників та як працювати з локальним вендором",
//             // "ТЕХНІЧНИЙ УРОК поглиблений: звук і світло (практикум із монтажу весілля)",
//             // "Урок про кордони — що робити із замовниками, котріі дзвонять вдень, уночі та на вихідних “просто поговорити”", 
//         ],
//         addMaterials: [
//             {
//                 icon: MP4,
//                 text: "План-схеми залу в фото і відео"
//             },
//             {
//                 icon: PDF,
//                 text: "Анкета для нареченого"
//             },
//             {
//                 icon: PDF,
//                 text: "Анкета для нареченої"
//             },
//             {
//                 icon: PDF,
//                 text: "Варіанти локацій Львів"
//             },
//             {
//                 icon: XLSX,
//                 text: "Шаблон кошторису весілля Mopis"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis Photo"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis Video"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis HOST"
//             },
//             {
//                 icon: XLSX,
//                 text: "Меню - приклад 1"
//             },
//             {
//                 icon: PDF,
//                 text: "Меню - приклад 2"
//             },
//             {
//                 icon: PDF,
//                 text: "Template check-sheet by Mopis"
//             },
//             {
//                 icon: DOC,
//                 text: "Формування кошторису в Mopis"
//             },
//             {
//                 icon: XLSX,
//                 text: "Шаблон кошторису весілля Mopis"
//             },
//             {
//                 icon: XLSX,
//                 text: "Estimate template by Mopis"
//             },
//             {
//                 icon: XLSX,
//                 text: "Приклад фінального кошторису 1"
//             },
//             {
//                 icon: XLSX,
//                 text: "Приклад фінального кошторису 2"
//             },
//             {
//                 icon: XLSX,
//                 text: "Приклад першого приблизного кошторису на Комо (прораховано 3 варіанти бюджету)"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis. Locations Croatia"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis. Locations Como"
//             },
//             {
//                 icon: PDF,
//                 text: "Шаблон чек-листа підготовки весілля Mopis"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis Music Bands"
//             },
//             {
//                 icon: PDF,
//                 text: "Questionnaire"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis. Структура презентації концепції весілля"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis. Правила презентації концепції"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад концепції 1"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад концепції 2"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад концепції 3"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis. Вибір декораторів"
//             },
//             {
//                 icon: PDF,
//                 text: "Кейс 1. Таймінг наречені"
//             },
//             {
//                 icon: PDF,
//                 text: "Кейс 1. Таймінг координатора"
//             },
//             {
//                 icon: PDF,
//                 text: "Кейс 2. Таймінг наречені"
//             },
//             {
//                 icon: PDF,
//                 text: "Кейс 2. Таймінг координатори"
//             },
//             {
//                 icon: PDF,
//                 text: "Кейс 3. Таймінг координатора (комендантська година)"
//             },
//             {
//                 icon: PDF,
//                 text: "Кейс 4. Timeline by Mopis"
//             },
//             {
//                 icon: DOC,
//                 text: "Чек-лист на вінчання"
//             },
//             {
//                 icon: DOC,
//                 text: "Чек-лист на церемонію"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад карнета з реального проєкту"
//             },
//             {
//                 icon: PDF,
//                 text: "KAZKA_HOSPITALITY"
//             },
//             {
//                 icon: PDF,
//                 text: "KAZKA_TECH"
//             },
//             {
//                 icon: PDF,
//                 text: "Приблизні норми грам на особу - Mopis"
//             },
//             {
//                 icon: JPG,
//                 text: "wedding-reception-floorplan-150guests-1280x708"
//             },
//         ]
//     }, 

//     // BLOCK #3

//     {
//         name: "Фінальна підготовка весілля",
//         description: "В результаті ти підготуєш фінальні документи і процеси перед весільним днем, щоб все пройшло чітко та якісно",
//         stepsBegginer: [
//             "Документація координатора тв чек-листи підрядникам",
//             "Визначення кількості координаторів на весілля",
//             "Формування команди координаторів на проєкт. Розподіл завдань",
//             "Остання зустріч з нареченими перед весіллям",
//             "Документація координатора. План монтажу",
//             "Скринька координатора",
//             "Весільні традиції",
//             "Урок від топ-фотографа І. Майхеркевича",
//             "Зум з Алісою"
//         ],
//         stepsProfi: [
//             // "Організація роботи, коли кілька проєктів в один день",
//             // "Мудрість життя — висновки та інсайди Mopis із сезону 2024 ",
//             // "Зміни кошторису в останній момент — що робити та як реагувати",
//             // "Приклад чек-листа координаторів масштабного проєкту (від 6 координаторів на майданчику)",
//             // "ART OF WEDDING — про створення масштабних весіль",
//             // "Урок про сервіс і підхід Mopis (із прикладом реальної планьорки координаторів перед проєктом)",
//             // "Урок про креатив і концептуальні весілля",
//             // "Весілля в Європі: структура таймінгу та наповнення",
//             // "Весілля в Європі: фінальна підготовка",
//             // "Досвід Mopis в організації весіль у Польщі ",
//             // "Весілля в Європі: відео із запрошеними спікерами"
//         ],
//         addMaterials: [
//             {
//                 icon: PDF,
//                 text: "Mopis. Розсадка гостей"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis. Приклад контакт-листа"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад чек-листа локації 1"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад чек-листа локації 2"
//             },
//             {
//                 icon: DOC,
//                 text: "Mopis. Розсадка гостей алфавітна"
//             },
//             {
//                 icon: DOC,
//                 text: "Mopis. Приклад плану монтажу"
//             },
//             {
//                 icon: PDF,
//                 text: "Приклад чек-листа фото- та відеозйомки"
//             },
//             {
//                 icon: PDF,
//                 text: "Mopis. Чек-лист фото- та відеозйомки"
//             },
//             {
//                 icon: MP4,
//                 text: "Mopis. Чек-лист роботи ресторану"
//             },
//             {
//                 icon: DOC,
//                 text: "Mopis. Наповнення скриньки координатора"
//             },
//         ]
//     }, 

//     // BLOCK #4

//     {
//         name: "Весільний день",
//         description: "В результаті ти дізнаєшся як проходить власне весільний день. Навчишся всім процесам та нюансам координації",
//         stepsBegginer: [
//             "Правила та принципи успішного проєкту",
//             "Координатор нареченої. Координація ранку",
//             "Координація прогулянки та вінчання", 
//             "Підготовка локації. Фінальні брифи",
//             "Координація банкету. Розподіл завдань та зони відповідальності",
//             "Урок про форс-мажори",
//             "Кроки по завершенні весілля. Закриття проєкту. Аналіз показників",
//             "Зум з запрошеним спікером"
//         ],
//         stepsProfi: [
//             // "Керування масштабним проєктом — роль організатора на майданчику",
//             // "Приклад і аналіз координації масштабного проєкту",
//             // "Урок із запрошеним спікером — топовим організатором в Україні та Європі", 
//             // "Координація весілля в Європі",
//             // "Зйомка контенту під час проєкту",
//             // "Урок від керуючого декоратора Mopis",
//             // "“Що пішло не так” — про складні ситуації та як їх вирішувати"
//         ],
//         addMaterials: []
//     }, 

//     // BLOCK #5

//     {
//         name: "Маркетинг і продажі",
//         description: "В результаті ти дізнаєшся як сформувати портфоліо,  маркетингову стратегію. Розробиш позиціонування та чіткий план з залучення клієнтів.",
//         stepsBegginer: [
//             "Ціна на послуги Весільного організатора. Фіксований гонорар чи %",
//             "ЦА та позиціонування. Визначення своїх сильних сторін",
//             "Створення послуги “Організація весілля”. Презентація",
//             "Портфоліо з нуля",
//             "Маркетингова стратегія. Планування бюджету",
//             "Урок про Instagram",
//             "Урок від таргетолога Mopis",
//             "Робота із заявками та перетворення їх у клієнтів",
//             "Перша зустріч і продаж послуги",
//             "Зум з Алісою"
//         ],
//         stepsProfi: [
//             // "Оплата роботи команди, організаторам і координаторам. Огляд і плюси-мінуси різних форматів",
//             // "Бренди, продукти та їхня цінність",
//             // "Розбір маркетингової кампанії Mopis 2024-2025 (що вдалося, а що ні — приклади, цифри та інсайди)",
//             // "CJM – CUSTOMER JOURNEY MAP",
//             // "Tone of voice релевантний ЦА",
//             // "Як у Mopis ми плануємо постинг весілля",
//             // "Як створювати контент, який виглядає дорого, навіть якщо весілля з невеликим бюджетом (із прикладами)",
//             // "Урок про налаштування реклами в Інстаграм (від таргетолога Mopis)",
//             // "Zoom-зустріч “Які дії робити, щоб саме твої клієнти приходили”",
//             // "Весільна агенція — побудова ефективної та прибуткової моделі роботи"
//         ],
//         addMaterials: []
//     }, 

//     // BLOCK #6

//     // {
//     //     name: "Маркетинг та позиціонування",
//     //     description: "В результаті ти дізнаєшся як сформувати портфоліо,  маркетингову стратегію. Розробиш позиціонування та чіткий план з залучення клієнтів",
//     //     stepsBegginer: [
//     //         "Розбір маркетигової кампанії Mopis 2024",
//     //         "Ціна на послуги весільного організатора",
//     //         "Визначення цільової аудиторії. Позиціонування",
//     //         "CJM – CUSTOMER JOURNEY MAP. Створення власного бренду",
//     //         "Портфоліо",
//     //         "Маркетингова стратегія. Де знаходити клієнтів",
//     //         "INSTAGRAM"
//     //     ],
//     //     stepsProfi: [],
//     //     addMaterials: []
//     // }, 

//     // BLOCK #7

//     {
//         name: "Завершальний",
//         description: "В результаті ти сформуєш свій покроковий план дій по завершенню курсу. Отримаєш inspiration-гайд для натхнення та розвитку. А ще... повіриш у себе",
//         stepsBegginer: [
//             "Чек-лист дій після завершення курсу",
//             "Тренди 2025–2026 р.",
//             "Фінальна Zoom-зустріч"
//         ],
//         stepsProfi: [],
//         addMaterials: [
//             {
//                 icon: DOC,
//                 text: "Чек-лист дій по завершенні курсу"
//             },
//             {
//                 icon: PDF,
//                 text: "inspiration-гайд"
//             }
//         ]
//     }, 
// ];



// /////////////



// export const programDataPro = [

//     // BLOCK #0

//     {
//         name: "Преднавчання",
//         description: "В результаті ми розберемо усі організаційні питання, задамо правильний настрій і попрацюємо над мисленням для максимально ефективного навчання",
//         stepsBegginer: [
//             "Welcome-зустріч “Знайомство”",
//             "Inspiration відео “Як отримати від навчання максимум”",
//             "Мотиваційне відео від команди Mopis (знято в Італії)",
//             "Зустріч із психологом “Мислення для успіху — долаємо страхи та мотивуємо себе на результативне навчання”",
//             "Налаштовуємося на хвилю dream-проєктів — підбірка натхнення",
//             "Фіксуємо точку 0 та проводимо аналіз сезону 2024",
//             "Цілі на курс",
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     },

//     // BLOCK #1

//     {
//         name: "Команда та внутрішні процеси",
//         description: "",
//         stepsBegginer: [
//             "Команда: з чого почати, які посади важливі. Part-time & Full time. Домовленості, оплати",
//             "Делегування. Як довірити комусь своє весілля. Про організацію роботи, контроль, довіру та відповідальність",
//             "Презентації клієнтам — брендування та нові стандарти. Урок від А. Глазової",
//             "ТЕХНІЧНИЙ УРОК поглиблений: звук і світло (практикум із монтажу весілля)",
//             "Організація роботи, коли в роботі паралельно 10+ проєктів. Розподіл часу та пріоритетів",
//             "Організація роботи, коли кілька проєктів в один день",
//             "Керування масштабним проєктом — роль організатора на майданчику",
//             "Приклад і аналіз координації масштабного проєкту",
//             "Типи складних замовників і комунікація з ними",
//             "Оплата роботи команди, організаторам і координаторам. Огляд і плюси-мінуси різних форматів",
//             "Урок про кордони — що робити із замовниками, котрі дзвонять вдень, уночі та на вихідних “просто поговорити”",
//             "Зум з запрошеним спікером"
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     }, 

//     // BLOCK #2

//     {
//         name: "Фінансовий ріст",
//         description: "",
//         stepsBegginer: [
//             "Кошторис проєкту: як збільшити дохід організатора",
//             "Робота з дорогим замовником: помилки, принципи, поради",
//             "Кошторис проєкту: як працювати з кошторисом, коли бюджет великий",
//             "Зміни кошторису в останній момент — що робити та як реагувати", 
//             "Запис уроку з Ю. Солодченко про роботу з преміум-сегментом",
//             "Зум з запрошеним спікером"
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     }, 

//     // BLOCK #3

//     {
//         name: "Маркетинг",
//         description: "",
//         stepsBegginer: [
//             "Як у Mopis ми плануємо постинг весілля",
//             "Як створювати контент, який виглядає дорого, навіть якщо весілля з невеликим бюджетом (із прикладами)",
//             "Робота з соціальними мережами, позиціонування, УТП та ЦА",
//             "Маркетингова стратегія",
//             "Як організатору просувати себе в інстаграм",
//             "Урок від таргетолога Mopis",
//             "Розбір маркетингової кампанії Mopis 2024-2025 (що вдалося, а що ні — приклади, цифри та інсайди)",
//             "Робота з заявками та перетворення їх в клієнтів",
//             "Проведення першої зустрічі",
//             "CJM",
//             "Зум з Алісою",
//             "Персональний аналіз сторінки в інста від Аліси"
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     }, 

//     // BLOCK #4

//     {
//         name: "Натхнення",
//         description: "",
//         stepsBegginer: [
//             "Розбір концепції весілля з бюджетом 100 000+ $",
//             "Мудрість життя — висновки та інсайди Mopis із сезону 2024",
//             "Урок про сервіс і підхід Mopis", 
//             "“Що пішло не так” — про складні ситуації та як їх вирішувати",
//             "Тренди 2025–2026 р",
//             "Чек-лист дій після завершення курс",
//             "Фінальна зустріч",
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     }, 

//     // BLOCK #5

//     // {
//     //     name: "Маркетинг і продажі",
//     //     description: "В результаті ти дізнаєшся як сформувати портфоліо,  маркетингову стратегію. Розробиш позиціонування та чіткий план з залучення клієнтів.",
//     //     stepsBegginer: [
//     //         "Ціна на послуги Весільного організатора. Фіксований гонорар чи %",
//     //         "ЦА та позиціонування. Визначення своїх сильних сторін",
//     //         "Створення послуги “Організація весілля”. Презентація",
//     //         "Портфоліо з нуля",
//     //         "Маркетингова стратегія. Планування бюджету",
//     //         "Урок про Instagram",
//     //         "Урок від таргетолога Mopis",
//     //         "Робота із заявками та перетворення їх у клієнтів",
//     //         "Перша зустріч і продаж послуги",
//     //         "Зум з Алісою"
//     //     ],
//     //     stepsProfi: [
//     //         "Оплата роботи команди, організаторам і координаторам. Огляд і плюси-мінуси різних форматів",
//     //         "Бренди, продукти та їхня цінність",
//     //         "Розбір маркетингової кампанії Mopis 2024-2025 (що вдалося, а що ні — приклади, цифри та інсайди)",
//     //         "CJM – CUSTOMER JOURNEY MAP",
//     //         "Tone of voice релевантний ЦА",
//     //         "Як у Mopis ми плануємо постинг весілля",
//     //         "Як створювати контент, який виглядає дорого, навіть якщо весілля з невеликим бюджетом (із прикладами)",
//     //         "Урок про налаштування реклами в Інстаграм (від таргетолога Mopis)",
//     //         "Zoom-зустріч “Які дії робити, щоб саме твої клієнти приходили”",
//     //         "Весільна агенція — побудова ефективної та прибуткової моделі роботи"
//     //     ],
//     //     addMaterials: []
//     // }, 

//     // BLOCK #6

//     {
//         name: "Бонуси",
//         description: "",
//         stepsBegginer: [
//             "Запис уроку з Л. Яровою",
//             "Урок від топ-фотографа і. Майхеркевича",
//             "Запис уроку з М. Сайкалі",
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     }, 

// ];


// ////////////


// export const programDataEurope = [

//     {
//         name: "Навчальна програма",
//         description: "",
//         stepsBegginer: [
//             "Як почати організовувати весілля в Європі",
//             "Організація в Україні та Європі: ключові відмінності",
//             "Чек-лист, кошторис та процес планування весілля в Європі",
//             "Логістика (команди, декору, техніки). Митні документи",
//             "Робота з локацією в Європі (підбір, комунікація, презентація для клієнта)",
//             "Весілля в Європі: де шукати підрядників та як працювати з локальним вендором",
//             "Структура таймінгу та наповнення для європейських весіль",
//             "Весілля в Європі: фінальна підготовка",
//             "Координація весілля в Європі",
//             "Робота з інтернаціональним замовником",
//             "Досвід Mopis в організації весіль у Польщі",
//             "Маркетинг в Європі: інструменти, які працюють",
//             "Інстаграм для ринку Європи",
//             "Як отримати клієнтів в Європі - Лана Оліфер",
//             "Специфіка роботи в Італії - Beatrice Fuso",
//             "Весілля в Польші від польського організатора Kurlovicz",
//             "Весілля в Франції - з французьким організатором",
//         ],
//         stepsProfi: [],
//         addMaterials: []
//     }

// ]






////////

export const programData = [

    // BLOCK #0

    {
        name: "Преднавчання",
        description: "В результаті ми розберемо усі організаційні питання, задамо правильний настрій і попрацюємо над мисленням для максимально ефективного навчання",
        stepsBegginer: [
            "Welcome-зустріч “Знайомство”",
            "Inspiration відео “Як отримати від навчання максимум”",
            "Мотиваційне відео від команди Mopis (знято в Італії)",
            "Зустріч із психологом “Мислення для успіху — долаємо страхи та мотивуємо себе на результативне навчання”",
            "Налаштовуємося на хвилю dream-проєктів — підбірка натхнення",
            "Цілі на курс",
        ],
        stepsProfi: [],
        addMaterials: []
    },

    // BLOCK #1

    {
        name: "Вступний",
        description: "В результаті ти отримаєш уявлення про роботу весільного організатора на реальних прикладах. Підготуєш повну базу для подальшої роботи",
        stepsBegginer: [
            "Знайомство з професією “Весільний організатор”. Обовʼязки організатора",
            "Різниця між організатором і координатором",
            "Стандарти та принципи роботи організатора ",
            "Комунікація із замовником",
            "Договір Весільного організатора (укр, англ)",
            "Програми для планування роботи",
            "Глосарій організатора (ключові терміни які використовуємо в роботі)",
            "Поділ весіль за масштабами",
        ],
        stepsProfi: [
            "Команда: з чого почати, які посади важливі. Part-time & Full time. Домовленості, оплати",
            "Делегування. Як довірити комусь своє весілля. Про організацію роботи, контроль, довіру та відповідальність",
            "Робота з дорогим замовником: помилки, принципи, поради",
            "Типи складних замовників і комунікація з ними",
            "Організація в Україні та Європі: ключові відмінності",
            "Як почати організовувати весілля в Європі",
        ],
        addMaterials: [
            {
                icon: PDF,
                text: "Організація весілля від Mopis"
            },
            {
                icon: PDF,
                text: "Приклад резюме Mopis"
            },
            {
                icon: MP4,
                text: "Планування проєкту в Asana"
            },
            {
                icon: PDF,
                text: "Шаблон резюме"
            },
            {
                icon: PDF,
                text: "Координація весілля від Mopis"
            },
            {
                icon: PDF,
                text: "Внутрішні стандарти роботи Mopis"
            },
            {
                icon: PDF,
                text: "Стандарти спілкування з нареченими Mopis"
            },
            {
                icon: MP4,
                text: "Перший досвід організації весілля закордоном"
            },
            {
                icon: DOC,
                text: "Договір - організація весілля Mopis"
            },
            {
                icon: DOC,
                text: "Agreement Mopis"
            },
            {
                icon: DOC,
                text: "Розписка про отримання коштів Mopis"
            },
        ]
    }, 

    // BLOCK #2

    {
        name: "Підготовка весілля. Покроковий розбір",
        description: "В результаті ти навчишся правильно виконувати всі етапи з підготовки весілля. Отримаєш необхідний список інструментів та шаблонів. Спроєктуєш знання на реальні приклади і кейси",
        stepsBegginer: [
            "Чек-лист підготовки весілля",
            "Перші кроки після підписання договору", 
            "Кошторис проєкту",
            "Підбір локації для пари. Підготовка презентації: смислові, візуальні, технічні особливості",
            "Підбір команди: фото, відео, ведучий, музичний супровід",
            "Приклад анкети для наречених Mopis",
            "Розробка концепції весілля. Приклади концепцій Mopis",
            "Розбір концептуального весілля Mopis",
            "Запрошення та поліграфія",
            "Візуальна складова свята. Комунікація з декораторами",
            "Формування таймінгу весільного дня. Наповнення свята",
            "Підготовка вінчання та церемонії",
            "Робота з зірковими артистами",
            "Складання меню на весілля",
            "Технічне забезпечення свята",
            "Зонування локації",
            "Запис зуму з дизайнеркою презентацій А. Глазовою"
        ],
        stepsProfi: [
            "Організація роботи, коли в роботі паралельно 10+ проєктів. Розподіл часу та пріоритетів",
            "Стандарти сервісу та перевершення очікувань (Діана, Івент-менеджер Edem Resort) ", 
            "Презентації клієнтам — брендування та нові стандарти (А.Глазова, best of the best дизайнерка презентацій, котра у свій час створила найтоповіші івенти Києва)",
            "Розбір концепції весілля з бюджетом 100 000+ $",
            "Кошторис проєкту: як отримати портфолійну картинку при дуже обмеженому кошторисі (з прикладами Mopis) ",
            "Кошторис проєкту: як працювати з кошторисом, коли бюджет великий ",
            "Кошторис проєкту: як збільшити дохід організатора",
            "Весілля в Європі: відмінності в чек-листі, кошторисі та процесі планування",
            "Весілля в Європі: логістика (команди, декору, техніки). Митні документи",
            "Весілля в Європі: робота з локацією (підбір, комунікація, презентація для клієнта) ",
            "Весілля в Європі: де шукати підрядників та як працювати з локальним вендором",
            "ТЕХНІЧНИЙ УРОК поглиблений: звук і світло (практикум із монтажу весілля)",
            "Урок про кордони — що робити із замовниками, котріі дзвонять вдень, уночі та на вихідних “просто поговорити”", 
        ],
        addMaterials: [
            {
                icon: MP4,
                text: "План-схеми залу в фото і відео"
            },
            {
                icon: PDF,
                text: "Анкета для нареченого"
            },
            {
                icon: PDF,
                text: "Анкета для нареченої"
            },
            {
                icon: PDF,
                text: "Варіанти локацій Львів"
            },
            {
                icon: XLSX,
                text: "Шаблон кошторису весілля Mopis"
            },
            {
                icon: PDF,
                text: "Mopis Photo"
            },
            {
                icon: PDF,
                text: "Mopis Video"
            },
            {
                icon: PDF,
                text: "Mopis HOST"
            },
            {
                icon: XLSX,
                text: "Меню - приклад 1"
            },
            {
                icon: PDF,
                text: "Меню - приклад 2"
            },
            {
                icon: PDF,
                text: "Template check-sheet by Mopis"
            },
            {
                icon: DOC,
                text: "Формування кошторису в Mopis"
            },
            {
                icon: XLSX,
                text: "Шаблон кошторису весілля Mopis"
            },
            {
                icon: XLSX,
                text: "Estimate template by Mopis"
            },
            {
                icon: XLSX,
                text: "Приклад фінального кошторису 1"
            },
            {
                icon: XLSX,
                text: "Приклад фінального кошторису 2"
            },
            {
                icon: XLSX,
                text: "Приклад першого приблизного кошторису на Комо (прораховано 3 варіанти бюджету)"
            },
            {
                icon: PDF,
                text: "Mopis. Locations Croatia"
            },
            {
                icon: PDF,
                text: "Mopis. Locations Como"
            },
            {
                icon: PDF,
                text: "Шаблон чек-листа підготовки весілля Mopis"
            },
            {
                icon: PDF,
                text: "Mopis Music Bands"
            },
            {
                icon: PDF,
                text: "Questionnaire"
            },
            {
                icon: PDF,
                text: "Mopis. Структура презентації концепції весілля"
            },
            {
                icon: PDF,
                text: "Mopis. Правила презентації концепції"
            },
            {
                icon: PDF,
                text: "Приклад концепції 1"
            },
            {
                icon: PDF,
                text: "Приклад концепції 2"
            },
            {
                icon: PDF,
                text: "Приклад концепції 3"
            },
            {
                icon: PDF,
                text: "Mopis. Вибір декораторів"
            },
            {
                icon: PDF,
                text: "Кейс 1. Таймінг наречені"
            },
            {
                icon: PDF,
                text: "Кейс 1. Таймінг координатора"
            },
            {
                icon: PDF,
                text: "Кейс 2. Таймінг наречені"
            },
            {
                icon: PDF,
                text: "Кейс 2. Таймінг координатори"
            },
            {
                icon: PDF,
                text: "Кейс 3. Таймінг координатора (комендантська година)"
            },
            {
                icon: PDF,
                text: "Кейс 4. Timeline by Mopis"
            },
            {
                icon: DOC,
                text: "Чек-лист на вінчання"
            },
            {
                icon: DOC,
                text: "Чек-лист на церемонію"
            },
            {
                icon: PDF,
                text: "Приклад карнета з реального проєкту"
            },
            {
                icon: PDF,
                text: "KAZKA_HOSPITALITY"
            },
            {
                icon: PDF,
                text: "KAZKA_TECH"
            },
            {
                icon: PDF,
                text: "Приблизні норми грам на особу - Mopis"
            },
            {
                icon: JPG,
                text: "wedding-reception-floorplan-150guests-1280x708"
            },
        ]
    }, 

    // BLOCK #3

    {
        name: "Фінальна підготовка весілля",
        description: "В результаті ти підготуєш фінальні документи і процеси перед весільним днем, щоб все пройшло чітко та якісно",
        stepsBegginer: [
            "Документація координатора тв чек-листи підрядникам",
            "Визначення кількості координаторів на весілля",
            "Формування команди координаторів на проєкт. Розподіл завдань",
            "Остання зустріч з нареченими перед весіллям",
            "Документація координатора. План монтажу",
            "Скринька координатора",
            "Весільні традиції",
            "Урок від топ-фотографа І. Майхеркевича"
        ],
        stepsProfi: [
            "Організація роботи, коли кілька проєктів в один день",
            "Мудрість життя — висновки та інсайди Mopis із сезону 2024 ",
            "Зміни кошторису в останній момент — що робити та як реагувати",
            "Приклад чек-листа координаторів масштабного проєкту (від 6 координаторів на майданчику)",
            "ART OF WEDDING — про створення масштабних весіль",
            "Урок про сервіс і підхід Mopis (із прикладом реальної планьорки координаторів перед проєктом)",
            "Урок про креатив і концептуальні весілля",
            "Весілля в Європі: структура таймінгу та наповнення",
            "Весілля в Європі: фінальна підготовка",
            "Досвід Mopis в організації весіль у Польщі ",
            "Весілля в Європі: відео із запрошеними спікерами"
        ],
        addMaterials: [
            {
                icon: PDF,
                text: "Mopis. Розсадка гостей"
            },
            {
                icon: PDF,
                text: "Mopis. Приклад контакт-листа"
            },
            {
                icon: PDF,
                text: "Приклад чек-листа локації 1"
            },
            {
                icon: PDF,
                text: "Приклад чек-листа локації 2"
            },
            {
                icon: DOC,
                text: "Mopis. Розсадка гостей алфавітна"
            },
            {
                icon: DOC,
                text: "Mopis. Приклад плану монтажу"
            },
            {
                icon: PDF,
                text: "Приклад чек-листа фото- та відеозйомки"
            },
            {
                icon: PDF,
                text: "Mopis. Чек-лист фото- та відеозйомки"
            },
            {
                icon: MP4,
                text: "Mopis. Чек-лист роботи ресторану"
            },
            {
                icon: DOC,
                text: "Mopis. Наповнення скриньки координатора"
            },
        ]
    }, 

    // BLOCK #4

    {
        name: "Весільний день",
        description: "В результаті ти дізнаєшся як проходить власне весільний день. Навчишся всім процесам та нюансам координації",
        stepsBegginer: [
            "Правила та принципи успішного проєкту",
            "Координатор нареченої. Координація ранку",
            "Координація прогулянки та вінчання", 
            "Підготовка локації. Фінальні брифи",
            "Координація банкету. Розподіл завдань та зони відповідальності",
            "Урок про форс-мажори",
            "Кроки по завершенні весілля. Закриття проєкту. Аналіз показників"
        ],
        stepsProfi: [
            "Керування масштабним проєктом — роль організатора на майданчику",
            "Приклад і аналіз координації масштабного проєкту",
            "Урок із запрошеним спікером — топовим організатором в Україні та Європі", 
            "Координація весілля в Європі",
            "Зйомка контенту під час проєкту",
            "Урок від керуючого декоратора Mopis",
            "“Що пішло не так” — про складні ситуації та як їх вирішувати"
        ],
        addMaterials: []
    }, 

    // BLOCK #5

    {
        name: "Маркетинг і продажі",
        description: "В результаті ти дізнаєшся як сформувати портфоліо,  маркетингову стратегію. Розробиш позиціонування та чіткий план з залучення клієнтів.",
        stepsBegginer: [
            "Ціна на послуги Весільного організатора. Фіксований гонорар чи %",
            "ЦА та позиціонування. Визначення своїх сильних сторін",
            "Створення послуги “Організація весілля”. Презентація",
            "Портфоліо з нуля",
            "Маркетингова стратегія. Планування бюджету",
            "Урок про Instagram",
            "Урок від таргетолога Mopis",
            "Робота із заявками та перетворення їх у клієнтів",
            "Перша зустріч і продаж послуги"
        ],
        stepsProfi: [
            "Оплата роботи команди, організаторам і координаторам. Огляд і плюси-мінуси різних форматів",
            "Бренди, продукти та їхня цінність",
            "Розбір маркетингової кампанії Mopis 2024-2025 (що вдалося, а що ні — приклади, цифри та інсайди)",
            "CJM – CUSTOMER JOURNEY MAP",
            "Tone of voice релевантний ЦА",
            "Як у Mopis ми плануємо постинг весілля",
            "Як створювати контент, який виглядає дорого, навіть якщо весілля з невеликим бюджетом (із прикладами)",
            "Урок про налаштування реклами в Інстаграм (від таргетолога Mopis)",
            "Zoom-зустріч “Які дії робити, щоб саме твої клієнти приходили”",
            "Весільна агенція — побудова ефективної та прибуткової моделі роботи"
        ],
        addMaterials: []
    }, 

    // BLOCK #6

    // {
    //     name: "Маркетинг та позиціонування",
    //     description: "В результаті ти дізнаєшся як сформувати портфоліо,  маркетингову стратегію. Розробиш позиціонування та чіткий план з залучення клієнтів",
    //     stepsBegginer: [
    //         "Розбір маркетигової кампанії Mopis 2024",
    //         "Ціна на послуги весільного організатора",
    //         "Визначення цільової аудиторії. Позиціонування",
    //         "CJM – CUSTOMER JOURNEY MAP. Створення власного бренду",
    //         "Портфоліо",
    //         "Маркетингова стратегія. Де знаходити клієнтів",
    //         "INSTAGRAM"
    //     ],
    //     stepsProfi: [],
    //     addMaterials: []
    // }, 

    // BLOCK #7

    {
        name: "Завершальний",
        description: "В результаті ти сформуєш свій покроковий план дій по завершенню курсу. Отримаєш inspiration-гайд для натхнення та розвитку. А ще... повіриш у себе",
        stepsBegginer: [
            "Чек-лист дій після завершення курсу",
            "Тренди 2025–2026 р.",
            "Фінальна  Zoom-зустріч"
        ],
        stepsProfi: [],
        addMaterials: [
            {
                icon: DOC,
                text: "Чек-лист дій по завершенні курсу"
            },
            {
                icon: PDF,
                text: "inspiration-гайд"
            }
        ]
    }, 


    // ЄВРОПА

    {
        name: "Весілля в Європі",
        description: "",
        stepsBegginer: [
            "Як почати організовувати весілля в Європі",
            "Організація в Україні та Європі: ключові відмінності",
            "Чек-лист, кошторис та процес планування весілля в Європі",
            "Логістика (команди, декору, техніки). Митні документи",
            "Робота з локацією в Європі (підбір, комунікація, презентація для клієнта)",
            "Весілля в Європі: де шукати підрядників та як працювати з локальним вендором",
            "Структура таймінгу та наповнення для європейських весіль",
            "Весілля в Європі: фінальна підготовка",
            "Координація весілля в Європі",
            "Робота з інтернаціональним замовником",
            "Досвід Mopis в організації весіль у Польщі",
            "Маркетинг в Європі: інструменти, які працюють",
            "Інстаграм для ринку Європи",
            "Як отримати клієнтів в Європі - Лана Оліфер",
            "Специфіка роботи в Італії - Beatrice Fuso",
            "Весілля в Польші від польського організатора Kurlovicz",
            "Весілля в Франції - з французьким організатором",
        ],
        stepsProfi: [],
        addMaterials: []
    }
];