import './ProgramBlock.scss';
import {programData} from './program-block-data';
import StageItem from './StageItem/StageItem';
// import {useState} from 'react';

function ProgramBlock () {
    // const [toggleState, setToggleState] = useState(1);

    // const toggleTab = (index) => {
    //     setToggleState(index);
    // }

    // const currentProgramData =
    //     toggleState === 1 ? programDataBegginer :
    //     toggleState === 2 ? programDataPro :
    //     toggleState === 3 ? programDataEurope :
    //     [];

    return (
        <section className='program-block' id='program'>
            <div className='program-block-container'>
                <div className='program-block-title-box'>
                    <h3>Програма курсу</h3>

                    {/* <div className='program-block-tabs-box'>
                        <span>оберіть тариф</span>

                        <div className='program-block-tabs-box-btns'>
                            <button 
                                className={toggleState === 1 ? 'active' : ''} 
                                onClick={() => toggleTab(1)}
                            >Beginner</button>

                            <button 
                                className={toggleState === 2 ? 'active' : ''} 
                                onClick={() => toggleTab(2)}
                            >Pro</button>

                            <button 
                                className={toggleState === 3 ? 'active' : ''} 
                                onClick={() => toggleTab(3)}
                            >Європа</button>
                        </div>
                    </div> */}
                </div>

                <div className='program-block-info-box'>
                    {
                        programData.map((stage, index) => (
                            <StageItem
                                key={index}
                                number={index.toString().padStart(2, '0')}
                                name={stage.name}
                                description={stage.description}
                                stepsBegginer={stage.stepsBegginer}
                                stepsProfi={stage.stepsProfi}
                                addMaterials={stage.addMaterials}
                            />
                        ))
                    }
                </div>
            </div>
        </section>
    )
};

export default ProgramBlock;