const speakerData = [
    {
        name: "Лариса Ярова",
        nickName: "",
        text: "Premium Yarova events worldwide. Бутіковий організатор. Створює заходи ручної роботи в Україні та Європі з 2011 року",
        theme: "Як працювати з premium-клієнтами. Секрети побудови довіри та створення незабутніх вражень "
    },

    {
        name: "KURLOVICZ",
        nickName: "",
        text: `wedding planner ( top Poland wedding agency) `,
        theme: "Організація весілля в Польші: формат, таймінг та наповнення"
    },

    {
        name: "Юля Солодченко",
        nickName: "",
        text: `Solodchenko Events, організатор преміум-весіль`,
        theme: "Розбір кейсу організації масштабного весілля"
    },

    {
        name: "Beatrice Fuso",
        nickName: "",
        text: "Italian wedding planner",
        theme: "Що треба знати не локальному організатору про організацію весілля в Італії"
    },

    {
        name: "Аліна Глазова",
        nickName: "",
        text: "та сама дизайнерка презентацій",
        theme: "Як справляти гарне враження на клієнтів за допомогою презентацій"
    },

    // {
    //     name: "Дмитро Корнелюк",
    //     nickName: "@dmytro_korn",
    //     text: "Ведучий. Викладач УКУ. Екс радіо- та телеведучий. Виступав на TEDx.",
    //     theme: "Креатив у сфері івентів"
    // },

    // {
    //     name: "Володимир Іваш",
    //     nickName: "",
    //     text: "#brides #wezoree best photographer in USA",
    //     theme: "Шлях до вершини. Як стати #1 у весільній індустрії"
    // },

    {
        name: "Lana Olifer",
        nickName: "",
        text: "CEO of Lana Wedding Planner & COO of WEMA Global",
        theme: "Весілля в Європі: де отримати перших клієнтів та як з ними працювати"
    },

    {
        name: "Роман Лабінський",
        nickName: "",
        text: "керівник топової компанії з технічного забезпечення івентів",
        theme: "Звук та світло: практичний туторіал з майданчика та огляд процесу монтажу"
    },

    {
        name: "Назар Корнега",
        nickName: "",
        text: "таргетолог Mopis",
        theme: "Де шукати клієнтів та як налаштувати весільний таргет який працює"
    },

    {
        name: "Наталі Свідерська",
        nickName: "",
        text: "Stories Wedding Agency",
        theme: "Концептуальні весілля та наповнення свята: як створити wow-ефект працюючи з різним бюджетом"
    },

    // {
    //     name: "Діана Форман",
    //     nickName: "",
    //     text: "керівник івент-напрямку Edem Resort",
    //     theme: "Стандарти сервісу та перевершення очікувань"
    // },

    {
        name: "Лариса Галаз",
        nickName: "",
        text: "маркетолог Mopis",
        theme: "Інстаграм для весільних організаторів. Позиціонування та контент"
    },

    {
        name: "Ігор Майхеркевич",
        nickName: "",
        text: "фотограф, якого люблять організатори та наречені",
        theme: ""
    },

    // {
    //     name: "Мішель Сайкалі",
    //     nickName: "",
    //     text: "топ-ведучий України, креатор, режисер",
    //     theme: "Креативні весілля, приклади нестандартних рішень "
    // },
];

export default speakerData;